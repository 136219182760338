import { ElNotification } from 'element-plus'
const success = (msg) => {
  ElNotification({
    title: '提示',
    message: msg,
    type: 'success',
  })
}
const error = (msg) => {
  ElNotification({
    title: '提示',
    message: msg,
    type: 'error',
  })
}
export {
	error,success
}
