import { createApp,ref } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import '@/styles/index.css'
// import '@/styles/reset.css'
import {success,error} from '@/utils/tips.js'
import {get,post,upload} from '@/utils/request.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './permission.js';
import print from 'vue3-print-nb'
const app = createApp(App)
const AllCode=async()=>{
	const res=await get('/Code/GetCode',{"code":"is_ys&,is_norms&"});
	const all_code=res.result;
	app.provide('$all_code', all_code)
}

AllCode();
app.config.globalProperties.$success =success;
app.config.globalProperties.$error =error;
app.config.globalProperties.$get =get;
app.config.globalProperties.$post =post;
app.config.globalProperties.$upload =upload;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.provide('$get', get)
app.provide('$post', post)
app.provide('$success', success)
app.provide('$error', error)
app.provide('$upload', upload)
installElementPlus(app)
app.use(print);
app.use(store).use(router).mount('#app')
