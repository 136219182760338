import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
	{
		path:'/',
		name:'login',
		component: () => import('../views/login/index.vue')
	},
	{
		path:'/home',
		name:'home',
		component: () => import('../views/home/home.vue'),
		children: [
			{
				path:'/user',
				name:'user',
				component: () => import('../views/user/user.vue')
			},
			{
				path:'/goods',
				name:'goods',
				component: () => import('../views/goods/goods.vue')
			},
			{
				path:'/goods_img',
				name:'goods_img',
				component: () => import('../views/goodsImg/goodsImg.vue')
			},
			{
				path:'/order',
				name:'order',
				component: () => import('../views/order/order.vue')
			},
			{
				path:'/classify',
				name:'classify',
				component: () => import('../views/classify/classify.vue')
			},
			{
				path:'/census',
				name:'census',
				component: () => import('../views/census/census.vue')
			}
		]
	},
	
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
