/**
 * 设置token
 */
export const setItem=(key,value)=>{
	window.localStorage.setItem(key,value);
}

/**
 * 获取token
 */
export const getItem=(key)=>{
	return window.localStorage.getItem(key);
}

