/**
 * http.js 用来封装 Axios
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios';
import qs from 'qs';
import Router from '@/router/index'
import {error} from '@/utils/tips.js'
import {getItem,setItem} from '@/utils/store.js'
// 环境的切换
if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = 'http://localhost/Ntoy/public/index.php';
	// axios.defaults.baseURL = 'http://localhost/IOTEquipment/public/index.php';
	// axios.defaults.baseURL='http://192.168.50.229/IOTEquipment/public/index.php'
} else if (process.env.NODE_ENV == 'debug') {
} else if (process.env.NODE_ENV == 'production') {
    // axios.defaults.baseURL = '/ga_test/ga_api/public/index.php';//测试
	axios.defaults.baseURL = '/api/public/index.php';
}
//设置默认的请求超时时间
axios.defaults.timeout = 1000 * 120;
//设置默认的post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.headers.common["token"] = sessionStorage.getItem("token");
// axios.defaults.headers.common['token'] = sessionStorage.getItem('token');
// axios.defaults.headers.common['token'] =sessionStorage.getItem("token");
// axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("token");
//请求拦截器,可以把请求前的数据做处理
// console.log(getItem("token"))
axios.interceptors.request.use(
    config => {
		config.headers['token']=getItem("token");
		// config.headers['token']=window.localStorage.getItem("token");
        // 在请求发送之前做一些事 如显示loading效果
  //       config.headers['Authorization'] = sessionStorage.getItem("token");
		// config.headers['token'] = '123';
        return config;
    },
    error => {
        // 当出现请求错误是做一些事
        return Promise.reject(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    // 请求成功
    res => (res.status === 200 ? Promise.resolve(res) : Promise.reject(res)),
    error => {
        if (error.response) {
            switch (error.response.status) {
                // 404请求不存在
                case 404:
                    Toast({
                        message: '网络请求不存在',
                        duration: 1500,
                        forbidClick: true
                    });
                    break;
				case 500:
					Toast({
						message: '请求失败，未知错误',
						duration: 1500,
						forbidClick: true
					});
					break;
				case 504:
					Toast({
						message: '超时网络错误',
						duration: 1500,
						forbidClick: true
					});
					break;
                // 其他错误，直接抛出错误提示
                default:
                    Toast({
                        message: error.response.data.message,
                        duration: 1500,
                        forbidClick: true
                    });
            }
            return Promise.reject(error.response);
        } else {
            console.log('请求超时或断网', error);
        }
    }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params
            })
            .then(res => {
				if(res.data.errCode=="201"){
					setItem("token","");
					Router.push({path:'/'})
					return;
				}
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            });
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的body参数]
 * @param {Object} params [请求时携带的url参数]
 * @param {String} msg [toast提示信息]
 */
export function post(url, data, params, msg) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: url,
            params: params,
            data: qs.stringify(data),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        })
            .then(res => {
				if(res.data.errCode=="201"){
					setItem("token","");
					Router.push('/');
					return;
				}
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            });
    });
}

/**
 * post方法，对应上传文件请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的url参数]
 * @param {Object} data [请求时携带的body参数]
 */
export function upload(url, data) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
				if(res.data.errCode=="201"){
					setItem("token","");
					Router.push({path:'/'});
					return;
				}
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            });
    });
}
