import router from '@/router';
import {getItem} from '@/utils/store.js'

const whiteList=['login'];
router.beforeEach((to, from, next) => {
	// next();
	
  if (getItem("token")){
	  to.name=="login"?next('/home'):next();
  }else{
	  if(whiteList.indexOf(to.name)>-1){
		  // console.log("999999");return;
		  next();
	  }else{
		  // console.log(123213);return;
		  next('/');
	  }
  }
})