<template>
	<router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
	*{
	  padding: 0;
	  margin: 0;
	}
	html,body{
	  width: 100%;
	  height: 100%;
	}
</style>
